nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    
    background: #FDB660;
    

    .nav_title {
        margin-left: 20px;
        color: rgb(247, 247, 235);
    }
}

