.home {
    min-height: 100vh;
    overflow-y: scroll;
    background-color: rgb(247, 247, 235);
}

main {
    margin-top: 0;
    min-height: 88vh;
    max-width: 1025px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
    .home_img {
        height: 250px;
        width: auto;
    }

    h2 {
        font-size: xx-large;
        color:rgba(31, 38, 135, 0.864);
        text-shadow:  1px 1px 2px #fdb66045;
    } 

    .open_btn {
        margin: 15px 0;
        width: 80%;
        border: 0;
        outline: 0;
        font-size: 16px;
        border-radius: 10px;
        padding: 16px;
        background-color:rgba(31, 38, 135, 0.762);
        color:wheat;
        font-weight: bold;
        box-shadow: -5px -5px 20px white,  5px 5px 20px #BABECC;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        font-weight: 600;


        &:hover {
        box-shadow: -2px -2px 5px white, 2px 2px 5px #BABECC;
        }
        
        &:active {
        box-shadow: inset 1px 1px 2px #BABECC, inset -1px -1px 2px white;
        }
    }
}

@media only screen and (min-width: 1025px) {
    .home {
        overflow:auto;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    main {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


        .home_img {
            margin-top: 0;
            height: 400px;
            width: auto;
        }
        
    } 
  }