@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;300;400;500;600&display=swap');


.container {
    background-color: #F7F7EB;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0.5em;
        font-family: 'Lexend', sans-serif;
        font-size: 2.5em;
        color: #FDB660;
    }

    .login_img {
        height: 250px;
        width: auto;
        margin-bottom: 0;
    }

    .wave_img {
        width: 100%;
        position: fixed;
        bottom: 0;
    }

    form {
        z-index: 2;
        height: 50vh;
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        input {
            margin-top: 15px;
            width: 90%;
            color: #FDB660;
            font-size: large;

            border: 0;
            outline: 0;
            border-radius: 10px;
            padding: 16px;
            background-color:#EBECF0;

            margin-right: 16px/2;
            box-shadow:  inset 2px 2px 5px #BABECC, inset -5px -5px 10px white;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            appearance: none;
            -webkit-appearance: none;
        
            &:focus {
            box-shadow:  inset 1px 1px 2px #BABECC, inset -1px -1px 2px white;
            }
        }
        
        button {
            margin-top: 25px;
            width: 90%;
            background-color: rgba(31, 38, 135, 0.864);
            border: 0;
            outline: 0;
            font-weight: 600;
            font-size: 16px;
            border-radius: 10px;
            padding: 16px;
            color:wheat;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
        
            box-sizing: border-box;
            appearance: none;
            -webkit-appearance: none;
            
            &:hover, &:active {
                background-color: rgb(31, 38, 135);
                box-shadow:  inset 1px 1px 2px #263b89, inset -1px -1px 2px rgb(108, 104, 162);
            }
        }
        
       
        
        span {
            color: rgba(31, 38, 135, 0.864);
            text-decoration-style: none;

            &:hover, &:active {
                color: rgb(31, 38, 135);
            }
        }
        
        a {
            text-decoration: none;
        }
    }
    
    
}

@media screen and (min-width: 1000px) {
    .container {
        .login_img {
            height: 300px;
            width: auto;
            margin-bottom: 0;
        }
        form {
            width: 30%;

            button {
                box-shadow: -2px -2px 5px #eba34b, 2px 2px 5px #df973f;
                
                &:hover, &:active {
                    box-shadow:  inset 1px 1px 2px #263b89, inset -1px -1px 2px rgb(108, 104, 162);
                }
            }
        }
    }
}