.footer {
    width: 100%;
    height: 50px;
    background: #FDB660;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    position: sticky;
    bottom: 0;
    font-weight: 600;

}